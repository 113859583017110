import { Controller } from "stimulus";

function animateValue(element, start, end, duration) {
  let startTimestamp = null;

  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    const value = parseFloat(progress * (end - start) + start).toFixed(2);
    
    if (value < 1) {
      element.innerHTML = value;
    } else {
      element.innerHTML = Math.floor(value);
    }

    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };

  if (window.requestAnimationFrame) {
    window.requestAnimationFrame(step);
  }
}

export default class extends Controller {
  connect() {
    const $element = this.element;
    const value = parseFloat($element.innerText);
    const duration = Math.min(1000 + (value * 2), 2000);

    animateValue($element, 0, value, duration);
  }
}