import { Controller } from "stimulus";

let counter = 0;

const validate = $input => {
  let isValid = true;

  // add rules here
  // checkbox is checked etc.
  if (!$input.value) isValid = false;

  return isValid;
}

export default class extends Controller {
  static targets = ['stage'];

  fieldsetIsValid($fieldset) {
    let isValid = true;

    $fieldset.querySelectorAll('[name]').forEach($input => {
      if (isValid) {
        isValid = validate($input);
      }
    });

    return isValid;
  }

  change(event = {}) {
    const { target = null } = event;
    const $fieldset = this.stageTargets[counter];
    const fieldsetIsValid = this.fieldsetIsValid($fieldset);

    if (target) {
      $fieldset.closest('form').classList.toggle('continue', fieldsetIsValid);
    } else {
      // show the continue button if this fieldset doesn't contain one of its own
      if (!$fieldset.querySelector('button[type="submit"]')) {
        $fieldset.closest('form').classList.add('continue');
      }
    }
  }

  handleFieldInFieldset($fieldset) {
    const $nextElement = $fieldset.querySelector('[data-action*="stepped-form#change"]');

    if ($nextElement) {
      this.change({ target: $nextElement });
      $nextElement.focus();
    } else {
      this.change();
    }
  }

  submit(event) {
    const $fieldset = this.stageTargets[counter];
    const $nextFieldset = this.stageTargets[++counter];

    if (counter > 0) {
      this.element.classList.add('back');
    }
    
    if ($nextFieldset) {
      event.preventDefault();

      $fieldset.classList.remove('current', 'fadeInUp');
      $fieldset.classList.add('fadeInDown');
      
      $nextFieldset.classList.add('current');

      this.handleFieldInFieldset($nextFieldset);
    }

    // else it will continue to submit
  }

  back(event) {
    event.preventDefault();

    const $fieldset = this.stageTargets[counter];
    const $nextFieldset = this.stageTargets[--counter];

    $fieldset.classList.remove('current', 'fadeInDown');
    $fieldset.classList.add('fadeInUp');

    $nextFieldset.classList.add('current');

    this.handleFieldInFieldset($nextFieldset);

    if (counter === 0) {
      this.element.classList.remove('back');
    }
  }

  connect() {
    console.log('stepped form controller connect');

    console.log(this.stageTargets);
  }
}