import { Controller } from "stimulus";

export default class extends Controller {
  load(event) {
    const httpRequest = new XMLHttpRequest();
    const $element = event.target;
    const targetId = $element.href.split('#')[1];

    if (httpRequest) {
      if (!targetId) {
        throw new Error('You need to supply a #element-id in the link\'s href for ajax-content-controller to work');
      }

      event.preventDefault();
  
      httpRequest.onreadystatechange = () => {
        if (httpRequest.readyState === XMLHttpRequest.DONE) {
          if (httpRequest.status === 200) {
            const responseHtml = new DOMParser().parseFromString(httpRequest.responseText, 'text/html');
            const $targetElement = responseHtml.getElementById(targetId);

            if (!$targetElement) {
              throw new Error('Cannot find element in response with ID "' + targetId + '"');
            } else {
              this.element.innerHTML = $targetElement.outerHTML;

              this.element.scrollIntoView({ behavior: 'smooth' });
            }
    
          } else {
            console.error('There was a problem with the request.');
          }
        }
      };

      httpRequest.open('GET', $element.href);
      httpRequest.send();
    }
    
    // else we let the link be a link
  }

  connect() {
    console.log('ajax content controller')
  }
}