import { Controller } from "stimulus";

const config = {
  facebook: (url) => `https://www.facebook.com/sharer.php?u=${url}`,
  twitter: (url, description) => `https://twitter.com/intent/tweet?url=${url}&text=${description}`,
};

function socialWindow(url) {
  const size = 570;
  const left = (screen.width - size) / 2;
  const top = (screen.height - size) / 2;
  const params = `menubar=no,toolbar=no,status=no,width=${size},height=${size},top=${top},left=${left}`;

  window.open(url, 'ShareWindow', params);
}

export default class extends Controller {
  share(event) {
    const pageUrl = encodeURIComponent(document.querySelector('meta[property="og:url"]').getAttribute('content'));
    const description = encodeURIComponent(document.querySelector('meta[property="og:description"]').getAttribute('content'));

    socialWindow(config[event.target.getAttribute('data-platform')](pageUrl, description));
  }

  connect() {
    Object.keys(config).forEach(platform => this.element.querySelector(`[data-platform="${platform}"]`).classList.add('configured'));
  }
}